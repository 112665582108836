<template>
  <div style="min-height: 100vh; display: flex; flex-direction: column">
    <!-- 页面内容 -->
    <div style="flex-grow: 1; padding: 20px; box-sizing: border-box">
    </div>

    <!-- 底部信息栏 -->
    <div
      style="
        background-color: #2d2d2d;
        color: #ffffff;
        width: 100%;
        padding: 1rem; /* 减少 padding */
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      "
    >
      <div style="display: flex; justify-content: space-between; font-size: 0.14rem; box-sizing: border-box">
        <!-- 联系我们 -->
        <div>
          <p style="font-size: 0.2rem" class="befores">联系我们</p>
          <div style="display: flex; margin-top: 0.1rem"> <!-- 减少 margin -->
            <img src="../assets/images/address.png" style="width: 0.17rem; height: 0.2rem" />
            <p style="margin-left: 0.16rem">安徽省合肥市高新区习友路3333号中国声谷2-C栋</p>
          </div>
          <div style="display: flex; margin-top: 0.05rem">
            <img src="../assets/images/email.png" style="width: 0.22rem; height: 0.18rem" />
            <p style="margin-left: 0.16rem">kf@biaozhuncha.com</p>
          </div>
          <div style="display: flex; margin-top: 0.05rem">
            <img src="../assets/images/phone.png" style="width: 0.2rem; height: 0.19rem" />
            <p style="margin-left: 0.16rem">4006785363</p>
          </div>
        </div>

        <!-- 分隔线 -->
        <div style="display: flex; align-items: center; justify-content: center">
          <div style="width: 0.5px; height: 0.5rem; background-color: #ffffff"></div>
        </div>

        <!-- 关于我们 -->
        <div style="width: 5.77rem">
          <p style="font-size: 0.2rem" class="befores">关于我们</p>
          <p style="margin-top: 0.1rem; line-height: 0.25rem"> <!-- 减少 margin -->
            安徽慧制科技有限公司（简称“慧制”）。在智慧城市建设的大背景下，慧制专注于智慧城市标准制定、规划咨询、国际合作、核心技术产业转化等领域，致力于为我们的客户提供卓有成效的、不可替代的优质服务。
          </p>
        </div>

        <!-- 分隔线 -->
        <div style="display: flex; align-items: center; justify-content: center">
          <div style="width: 0.5px; height: 0.5rem; background-color: #ffffff"></div>
        </div>

        <!-- 二维码 -->
        <div style="display: flex; flex-direction: column; align-items: center">
          <img src="../assets/images/erweima.png" style="width: 1.07rem; height: 1.07rem" />
          <p style="margin-top: 0.1rem">扫一扫 下载标准查APP</p> <!-- 减少 margin -->
        </div>
      </div>

      <!-- 友情链接 -->
      <div style="display: flex; align-items: center; justify-content: center; margin-top: 0.1rem"> <!-- 减少 margin -->
        <p style="font-size: 0.2rem">友情链接：</p>
        <div>
          <a href="https://www.zcsmart.com/" target="_blank">
            <img src="../assets/images/zckj.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
          <a href="http://www.antaiib.com/" target="_blank">
            <img src="../assets/images/antai.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
          <a href="https://www.isoftstone.com/" target="_blank">
            <img src="../assets/images/tong.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
          <a href="https://www.tencent.com/zh-cn" target="_blank">
            <img src="../assets/images/tencent.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
          <a href="https://www.aliyun.com/" target="_blank">
            <img src="../assets/images/ali.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
          <a href="http://www.sbcvc.com/" target="_blank">
            <img src="../assets/images/sbcvc.png" style="width: 1rem; height: 0.38rem; margin-right: 0.1rem" />
          </a>
        </div>
      </div>

      <!-- 备案信息 -->
      <div style="font-size: 0.14rem; display: flex; align-items: center; justify-content: center; margin-top: 0.1rem"> <!-- 减少 margin -->
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="color: #ffffff">
          <span style="margin-right: 20px">© Copyright 2024 by 安徽慧制科技有限公司</span>
          <span>皖ICP备2024070365号-1</span>
        </a>
      </div>

      <!-- 底部留白 -->
      <div style="width: 100%; height: 0.1rem"></div> <!-- 减少高度 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
};
</script>

<style scoped>
/* 确保页面内容占满剩余空间 */
body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* 下划线样式 */
.befores {
  position: relative;
}

.befores::before {
  content: "";
  width: 0.4rem;
  height: 1px; /* 下划线高度 */
  background: #ffffff; /* 下划线颜色 */
  position: absolute;
  top: 110%;
}
</style>