<template>
	<div style="" class="about">
		<div class="header" >
			<div style="width: 100%;height: 40px;background-color: #fa6143;display: flex;align-items: center;justify-content: space-between;">
				<div style="color: #FFFFFF;font-size: 15px;cursor: pointer;margin-left: 180px;" @click="goindex">返回</div>
				<!-- <div style="margin-right: 220px;color: #FFFFFF;font-size: 15px;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 5px;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div> -->
			</div>
			<Header></Header>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>

		 <div style="width: 100%;display: flex;flex-direction: column;justify-content: space-around;align-items: center;margin-top: 30px;">
			 <div style="width: 100%;height: 40px;"></div>
			
			<el-form label-position="left"  :model="formLabelAlign" ref="bdform" :rules="rules">
			 <div>
				 <div style="display: flex;justify-content: center;margin-left: 60px;">
					 <el-form-item label="项目名称" label-width="80px" prop="prname_rule">
					    <!-- <el-input v-model="formLabelAlign.prname"></el-input> -->
					    <el-cascader
					        v-model="formLabelAlign.prname"
					        :options="options"
					        @change="handleChange" style="width: 620px;"></el-cascader>
					  </el-form-item>
				 </div>
				 <div style="display: flex;justify-content: space-around;">
					<div style="display: flex;flex-direction: column;align-items: flex-end;">
						
						<el-form-item label="公司名称" label-width="80px" prop="coname">
						  <el-input v-model="formLabelAlign.coname" style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="统一社会信用代码" label-width="140px" required>
						  <el-input v-model="formLabelAlign.credit_code" style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="标准名称" label-width="80px" prop="st_name">
						  <el-input v-model="formLabelAlign.st_name" style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="标准号" label-width="80px" required>
						  <el-input v-model="formLabelAlign.bz_code" style="width: 247px;"></el-input>
						</el-form-item>
					</div>
					<div style="margin-left: 50px;">
						<el-form-item label="联系人" label-width="80px" required>
						  <el-input style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="手机号码" label-width="80px" required>
						  <el-input v-model="formLabelAlign.phone" style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="开户行" label-width="80px" required>
						  <el-input style="width: 247px;"></el-input>
						</el-form-item>
						<el-form-item label="开户账号" label-width="80px" required>
						  <el-input style="width: 247px;"></el-input>
						</el-form-item>
					</div>
				 </div>
			 </div>
			</el-form>
			<div style="width: 100%;text-align: center;">
				<el-upload
				  class="upload-demo"
				  action="https://test.biaozhuncha.com/api/record/"
				  :on-remove="handleRemove"
				  :auto-upload="false"
				  :data="formLabelAlign"
				  ref="upload"
				  :on-success="succ"
				  :on-error="erros"
				  :limit="1"
				  :on-exceed="cclimit"
				  :with-credentials="true">
				  <el-button slot="trigger" size="small" type="primary" style="margin-left: 20px;">选取文件</el-button>
				  <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">提交审核</el-button>
				</el-upload>
			</div>
			 
		 </div>
		 
		 <!-- <div @click="open">点击</div> -->
			 <!-- <div style="width: 100%;height: 155px;"></div> -->

		<Bottom></Bottom>

	</div>
</template>

<script>
	import Header from '../common/Headers.vue'
	import Bottom from '../common/Bottom.vue'
	export default {
		components:{
			Header,
			Bottom
		},
		data() {
			
			
			var checkcode = (rule, value, callback)=>{
			console.log(value);
				if(value){
					console.log(value);
					this.$axios({
						url:'/policy/',
						
						params:{
							com_name:value
						}
					}).then(res=>{
						console.log(res);
						if(res.data.status == 200){
							this.formLabelAlign.credit_code = res.data.info
										callback()
						}else{
							callback(new Error(res.data.info));
						}
						// if(res.data.state == 404){
						// 	callback(new Error('请先登录'));
						// }else{
						// 	if(res.data.state == 202){
						// 		// this.$message.warning(res.data.info)
						// 		callback();
						// 	}else{
						// 		// callback(new Error('请填写完整'));
						// 		//修改此标准
						// 		if(res.data.state == 207){
						// 			callback(new Error(res.data.info));
						// 		}else{
						// 			// this.dialogVisible = true
						// 			// this.flagbutton = true
						// 			this.formLabelAlign.credit_code = res.data.info
						// 			callback()
						// 		}
								
						// 	}
						// }
						
					}).catch(err=>{
						// console.log(err);
					})
					// callback()
				}else{
					callback(new Error('请输入标准名称'));
				}
			}
			
			var checkCcname = (rule, value, callback)=>{
			console.log(value);
				if(value){
					console.log(value);
					this.$axios({
						url:'/policy/',
						
						params:{
							c_c_name:value
						}
					}).then(res=>{
						console.log(res);
						if(res.data.status == 200){
							this.formLabelAlign.bz_code = res.data.info
							console.log('----------');
										callback()
						}else{
							callback(new Error(res.data.info));
						}
						
						
					}).catch(err=>{
						// console.log(err);
					})
					// callback()
				}else{
					callback(new Error('请输入标准名称'));
				}
			}
			
			return {
				tokens:false,
				formLabelAlign: {
				          prname: '',
						  coname:'',
						  credit_code:'',//信用代码
						  st_name:'',
						  bz_code:'',
						  phone:JSON.parse(window.localStorage.getItem('token')).info.telephone
				        },
				rules:{
						coname: [
						  { required: true, validator:checkcode, trigger: 'blur' },
						  // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						],
						prname_rule:[
							{ required: true, trigger: 'blur',message:'请选择' },
						],
						st_name:[
						  { required: true, trigger: 'blur' ,validator:checkCcname,},
						  // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						],
				},
				options:[{
            value: '关于开展2024年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
            label: '关于开展2024年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
            },
			{
			value: '关于开展2023年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			label: '关于开展2023年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			},
			{
			value: '关于开展2022年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			label: '关于开展2022年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			},
			{
			value: '关于开展2021年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			label: '关于开展2021年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			},
			{
			value: '关于开展2020年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			label: '关于开展2020年合肥市推进标准化发展 若干政策奖励资金申报工作的通知',
			}
            ]
			}
		},
		mounted() {
			this.loginout()

		},
		methods: {
			 handleChange(value) {
			        console.log(value);
			      },
			submitUpload(){
				// console.log(this.$refs.upload.uploadFiles)
				// console.log(this.formLabelAlign)
				this.$refs["bdform"].validate(valid => {
				this.$message.success('提交成功')
				});
				
			},
			cclimit(){
						  this.$message.warning('最多只能上传一份文件')
			},
			erros(err){
						  console.log(err);
			},
			succ(res){
						  console.log(res);
						  // if(res.status == 203){
							 //  this.$message.success('感谢您的贡献！')
						  // }else{
							 //  this.$message.warning(res.info)
						  // }
			},
			handleRemove(file, fileList) {
			       // console.log(file, fileList);
			     },
			goindex(){
				this.$router.push({path:'/indext'})
			},
			open(){
				
				this.$axios({
					url:'/try/'
				}).then(res=>{
					console.log(res)
					const div = document.createElement('div');
					div.innerHTML = res.data.info;
					document.body.appendChild(div);
				})
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.about{
			width: 1920px;
		}
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 220px;
	}

	.btn {
		width: 60px;
		height: 36px;
		text-align: center;
		border: 1px solid #f32727;
		outline: none;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 14px;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 60px;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #0077AA;
}
.btnover{
	width: 280px;
	height: 60px;
	cursor: pointer;
	text-align: center;
	line-height: 60px;
	border: 1px solid #F32727;
	font-size: 16px;
	border-radius: 10px;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.aboutimg{
	background: url(../assets/images/about/aboutone.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.twoimg{
	background: url(../assets/images/about/abouttwo.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.jianbian{
	width: 698px;
	height: 175px;
	background: url(../assets/images/about/header.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
</style>
