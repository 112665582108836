<template>
	<div style="background-color: #f8f8f8;" class="about">
		<div class="header" >
			<div style="width: 100%;height: 40px;background-color: #fa6143;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 220px;color: #FFFFFF;font-size: 15px;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 5px;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div>
			</div>
			<Header></Header>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>

		 <div style="width: 100%;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
			 <div style="width: 100%;height: 40px;"></div>
			 <div style="width: 1200px;height: 240px;" class="aboutimg">
				 <div style="margin: 30px 0 0 30px;">
					 <div style="font-size: 26px;color: #101010;font-weight: 600;">简介</div>
					 <div style="width: 30px;height: 4px;background-color: #f9a31c;"></div>
					 <div style="width: 850px;font-size: 16px;color: #101010;margin-top: 20px;line-height: 30px;">
						 标准查是北京慧制科技有限公司旗下APP，是国内首创集标准规范与产品智能查询为一体的一款APP，于2021年12月1日正式上线。“标准查”APP已收录标准信息100多万条，注册用户突破1万人次，VIP付费用户突破千人。
					 </div>
				 </div>
			 </div>
			 <div style="width: 100%;height: 30px;"></div>
			 <div style="width: 1200px;height: 310px;display: flex;justify-content: flex-end;align-items: center;" class="twoimg">
				 <div class="jianbian">
					 <div style="margin: 20px 0 0 20px;">
					 					 <div style="font-size: 26px;color: #101010;font-weight: 600;">产品文化</div>
					 					 <div style="width: 30px;height: 4px;background-color: #f9a31c;"></div>
					 					 <div style="font-size: 16px;color: #101010;margin-top: 20px;">使命：标准助力创新与发展</div>
					 					 <div style="font-size: 16px;color: #101010;margin-top: 20px;">愿景：做您忠诚的标准专家、产品建议家。</div>
					 </div>
				 </div>
			 </div>
			 
		 </div>
		 
		 <!-- <div @click="open">点击</div> -->
		 <!-- <div class="chat-container">
		     <div class="message-list">
		       <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.role]">
		         <div class="message-content">{{ msg.content }}</div>
		       </div>
		     </div>
		     
		     <div class="input-area">
		       <input
		         v-model="inputMessage"
		         @keyup.enter="sendMessage"
		         placeholder="输入你的消息..."
		         :disabled="isLoading"
		       />
		       <button @click="sendMessage" :disabled="isLoading">
		         {{ isLoading ? '发送中...' : '发送' }}
		       </button>
		     </div>
		     
		     <div v-if="error" class="error-message">
		       {{ error }}
		     </div>
		   </div> -->
		   
		   
		   <!-- <div class="chat-container">
		         <div class="message-list">
		           <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.role]">
		             <div class="message-content">
		     
		               <span v-if="msg.role === 'assistant' && msg.isTyping">{{ msg.displayedContent }}</span>
		          
		               <span v-else>{{ msg.content }}</span>
		             </div>
		           </div>
		         </div>
		   
		        
		         <div class="input-area">
		           <input
		             v-model="inputMessage"
		             @keyup.enter="sendMessage"
		             placeholder="输入你的消息..."
		             :disabled="isLoading"
		           />
		           <button @click="sendMessage" :disabled="isLoading">
		             {{ isLoading ? '发送中...' : '发送' }}
		           </button>
		         </div>
		   
		        
		         <div v-if="error" class="error-message">
		           {{ error }}
		         </div>
				</div> -->
			 <div style="width: 100%;height: 55px;"></div>

		<Bottom></Bottom>

	</div>
</template>

<script>
	import axios from 'axios';
// import { callDeepSeekAPI } from '@/utils/deepseek'; // 导入封装的 API 函数
	import Header from '../common/Headers.vue'
	import Bottom from '../common/Bottom.vue'
	export default {
		components:{
			Header,
			Bottom
		},
		data() {
			return {
				tokens:false,
				 messages: [],
				      inputMessage: '',
				      isLoading: false,
				      error: null,
				      apiConfig: {
				        // 从 DeepSeek 后台获取以下信息
				        apiKey: 'sk-c19d74fd13594e13a198c24d84b4eb76',
				        apiUrl: 'https://api.deepseek.com/v1/chat/completions' // 例如：'https://api.deepseek.com/v1/chat/completions'
				      }
			}
		},
		mounted() {
			this.loginout()
			
			// 页面加载时，初始化第一条消息
			    this.addAssistantMessage("我是 DeepSeek，很高兴见到你！");

		},
		methods: {
			async sendMessage() {
			      if (!this.inputMessage.trim() || this.isLoading) return;
			
			      this.isLoading = true;
			      this.error = null;
			
			      try {
			        // 添加用户消息
			        this.messages.push({
			          role: "user",
			          content: this.inputMessage,
			        });
			
			        // 清空输入框
			        this.inputMessage = "";
			
			        // 模拟 assistant 回复
			        const aiResponse = "您好！我是DeepSeek-V3，一个由深度求索公司开发的智能助手，我会尽我所能为您提供帮助和信息解答。";
			        this.addAssistantMessage(aiResponse);
			
			        // 滚动到底部
			        this.$nextTick(() => {
			          this.scrollToBottom();
			        });
			      } catch (err) {
			        console.error("Error:", err);
			        this.error = "请求失败，请检查网络连接或API配置";
			      } finally {
			        this.isLoading = false;
			      }
			    },
			// 添加 assistant 消息并逐字加载
			    addAssistantMessage(text) {
			      const message = {
			        role: "assistant",
			        content: text,
			        displayedContent: "", // 当前显示的内容
			        isTyping: true, // 是否正在加载
			      };
			      this.messages.push(message);
			
			      // 逐字加载
			      this.typeWriter(message);
			    },
			
			    // 逐字加载逻辑
			    typeWriter(message) {
			      if (message.displayedContent.length < message.content.length) {
			        message.displayedContent += message.content[message.displayedContent.length];
			        setTimeout(() => this.typeWriter(message), 100); // 每 100 毫秒加载一个字
			      } else {
			        // 加载完成
			        message.isTyping = false;
			      }
			    },
			
			    // 滚动到底部
			    scrollToBottom() {
			      const messageList = this.$el.querySelector(".message-list");
			      messageList.scrollTop = messageList.scrollHeight;
			    },
			//  async sendMessage() {
			//       if (!this.inputMessage.trim() || this.isLoading) return;
			
			//       this.isLoading = true;
			//       this.error = null;
			
			//       try {
			//         // 添加用户消息
			//         this.messages.push({
			//           role: 'user',
			//           content: this.inputMessage
			//         });
			
			//         // 构造请求参数
			// //         const payload = {
			// //           model: "deepseek-chat", // 根据实际模型调整
			// //           messages: this.messages,
			// //           temperature: 0.7,
			// //           max_tokens: 1000
			// //         };
			
			// //         // 发送请求
			// //         const response = await axios.post(
			// //           this.apiConfig.apiUrl,
			// //           payload,
			// //           {
			// //             headers: {
			// //               'Authorization': `Bearer ${this.apiConfig.apiKey}`,
			// //               'Content-Type': 'application/json'
			// //             }
			// //           }
			// //         );
			
			// //         // 添加 AI 回复
			// //         const aiResponse = response.data.choices[0].message.content;
			//         this.messages.push({
			//           role: 'assistant',
			//           content: 'aiResponse'
			//         });
			
			//         // 清空输入
			//         this.inputMessage = '';
			// 		 // 新增：在收到回复后再次滚动
			// 		        this.$nextTick(() => {
			// 		          this.scrollToBottom();
			// 		        });
			//       } catch (err) {
			//         console.error('API Error:', err);
			//         this.error = '请求失败，请检查网络连接或API配置';
			//       } finally {
			//         this.isLoading = false;
			//       }
			//     },
			open(){
				
				this.$axios({
					url:'/try/'
				}).then(res=>{
					console.log(res)
					const div = document.createElement('div');
					div.innerHTML = res.data.info;
					document.body.appendChild(div);
				})
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.about{
			width: 1920px;
		}
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 220px;
	}

	.btn {
		width: 60px;
		height: 36px;
		text-align: center;
		border: 1px solid #f32727;
		outline: none;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 14px;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 60px;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #0077AA;
}
.btnover{
	width: 280px;
	height: 60px;
	cursor: pointer;
	text-align: center;
	line-height: 60px;
	border: 1px solid #F32727;
	font-size: 16px;
	border-radius: 10px;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.aboutimg{
	background: url(../assets/images/about/aboutone.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.twoimg{
	background: url(../assets/images/about/abouttwo.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.jianbian{
	width: 698px;
	height: 175px;
	background: url(../assets/images/about/header.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.chat-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #e2e2ff;
}

.message-list {
  margin-bottom: 20px;
  height: 400px;
  overflow: auto;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 8px;
}

.message {
  margin: 10px 0;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 15px;
  max-width: 80%; /* 设置最大宽度 */
  width: fit-content; /* 宽度根据内容自适应 */
  word-wrap: break-word; /* 长文本自动换行 */
}

.message.user {
  background-color: rgb(239, 246, 255);
  margin-left: auto; /* 用户消息靠右 */
  margin-right: 5px;
}

.message.assistant {
  background-color: #f5f5f5;
  margin-right: auto; /* AI 消息靠左 */
  margin-left: 5px;
}

.input-area {
  display: flex;
  gap: 10px;
}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
}
</style>
