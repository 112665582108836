<template>
	<div class="headerone" style="margin-top: 0.2rem;">
		
		<img src="../assets/images/newslogo.png" style="cursor: pointer;" class="logos" @click="goindex">
		
		<div style="" class="rightlogo">
			
			<div   v-for="(item,index) in headerlist" :key="index" class="sy">
						<!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
						  <el-menu-item :index="'/'+item.path" v-for="(item,index) in headerlist" :key="index">{{item.name}}</el-menu-item>
						</el-menu> -->
						
							<div @click="linkheader(item)" style="cursor:pointer;">{{item.name}}</div>
						
			</div>
			<div class="sy" style="cursor:pointer;" @click="opensu">标准补贴</div>
			<div class="sy" style="cursor:pointer;" @click="opelht" v-if="city">管理入口</div>
					
					<el-popover
					    placement="top-start"
					    width="300"
						title=""
					    trigger="hover"
						 popper-class="wrap5"
					    >
						<div style="text-align: center;">
							<div style="width: 100%;display: flex;justify-content: flex-end;" @click="pay">
								<button class="button1">
								  <span>开通会员</span>
								</button>
							</div>
							<div class="nottitle">
								<div v-if="vipflag">会员到期：{{viptime}}</div>
								<div v-else>您还不是标准查会员</div>
							</div>
							<p style="margin-top: 10px;">———解锁多项标准查会员权益———</p>
							<div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;">
								<div style="text-align: center;">
									<img src="../assets/images/index/sj.png" style="width: 36px;" alt="" />
									<p style="margin-top: 7px;">标准文本</p>
								</div>
								<div style="text-align: center;">
									<img src="../assets/images/index/zb.png" style="width: 36px;" alt="" />
									<p style="margin-top: 7px;">早晚报</p>
								</div>
								<div style="text-align: center;">
									<img src="../assets/images/index/gz.png" style="width: 36px;" alt="" />
									<p style="margin-top: 7px;">标准跟踪</p>
								</div>
								<div style="text-align: center;">
									<img src="../assets/images/index/textdown.png" style="width: 36px;" alt="" />
									<p style="margin-top: 7px;">PDF下载</p>
								</div>
							</div>
							
							<div style="font-weight: 700;font-size: 16px;display: flex;justify-content: space-around;align-items: center;color: #73483d;margin-top: 10px;">
								<p style="cursor: pointer;" @click="personalto">用户中心</p>
								<div @click="pathLogin" style="cursor:pointer;" v-if="tokens==false">登录/注册</div>
								<div @click="layout" style="cursor:pointer;" v-else>退出登录</div>
							</div>
							
						</div>
					   <div class="headhost" slot="reference">
					   	<img :src="headhost" v-if="tokens==true" class="fade-in-image" style="width: 0.34rem;height: 0.34rem;border-radius: 50%;">
					   </div>
					  </el-popover>
					  
			<div class="rightlogoq">
						<div @click="pathLogin" style="cursor:pointer;" v-if="tokens==false">登录/注册</div>
						<div @click="layout" style="cursor:pointer;" v-if="tokens==true">退出</div>
					</div>
					 
		</div>
		<!-- <div style="width: 200px;height: 50px;background-color: blue;" @click="dialogVisible = true">
			
		</div> -->
	</div>
</template>

<script>
	export default{
		name:'Tab',
		data(){
			return{
				headerlist:[{
							  name:'首页',
							  path:'indext'
				},
				{
							  name:'录入',
							  path:'record'
				},	 
				{
							  name:'关于我们',
							  path:'about'
				}
				],
				viptime:'',
				vipflag:false,
				headhost:'',
				tokens:null,
				city:null,//权限
			}
		},
		created(){
			this.loginout()
		},
		mounted() {
			if(this.tokens == true){
				const img = this.$el.querySelector('.fade-in-image');
				    img.onload = () => {
				      img.classList.add('loaded');
				    };
			}
			
		},
		methods:{
			opensu(){
				
					
					this.$axios({
					  url:'/custom_search/',
					  params:{
						  counties:'1'
					  }
					}).then(res=>{
					  console.log(res);
					  if(res.data.status == 200){
						this.$router.push({path:'/subsidy'})
					  }else{
						  this.$message.error(res.data.message)
					  }
					
					
					})
					
						  
						
						
				
			},
			opelht(){
				
				this.$axios({
				  url:'/custom_search/',
				  params:{
					  counties:'1'
				  }
				}).then(res=>{
				  console.log(res);
				  if(res.data.status == 200){
					const url = 'http://admin.biaozhuncha.com';
					      window.open(url, '_blank');
				  }else{
					  this.$message.error(res.data.message)
				  }
				
				
				})
				
					  
					
					
			},
			personalto(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				const {href} = this.$router.resolve(
				{ 
				  path: '/personal'
			}
				)
				if(token){
					window.open(href, '_blank')
				}else{
					this.$message.error('您还没有登录')
				}
			},
			pay(){
				let token = JSON.parse(window.localStorage.getItem('token'))
					const {href} = this.$router.resolve(
					{ 
					  path: '/pay'
				}
					)
					if(token){
						window.open(href, '_blank')
					}else{
						this.$message.error('您还没有登录')
					}
			},
			goindex(){
				this.$router.push({path:'/indext'})
			},
			layout(){
				
				
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.tokens = false
					this.loginout()
				})
			},
			handleSelect(key, keyPath) {
			  // console.log(key, keyPath);
			},
			  handleClose(done) {
			        this.$confirm('确认关闭？')
			          .then(_ => {
			            done();
			          })
			          .catch(_ => {});
			      },
			linkheader(item){
				// console.log(item)
				// if(item.path=='indext'){
				// 	this.$router.push({path:'/indext'})
				// }
				// if(item.path=='news'){
				// 	this.$router.push({path:'/news'})
				// }
				// if(item.path=='about'){
				// 	this.$router.push({path:'/about'})
				// }
				
				// this.$axios({
				//   url:'/custom_search/',
				//   params:{
				// 	  counties:'1'
				//   }
				// }).then(res=>{
				//   console.log(res);
				//   if(res.data.status == 200){
				// 	const url = 'http://admin.biaozhuncha.com';
				// 	      window.open(url, '_blank');
				//   }else{
				// 	  this.$message.error(res.data.info)
				//   }
				
				
				// })
				
				
					let token = JSON.parse(window.localStorage.getItem('token'))
					
					if(item.path=='record'){
						if(token==null){
							this.$message.error('您还没有登录，请先登录！')
						}else{
							this.$router.push({path:'/'+item.path})
						}
					}else{
						this.$router.push({path:'/'+item.path})
					}
			},
			pathLogin(){
				this.$router.push({path:'/login'})
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				console.log(token);
				
				
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					console.log(token);
					if(token.info.telephone){
						this.$axios({
							url:'/push_info/',
							params:{
								input:token.info.telephone
							}
						}).then(res=>{
							console.log(res);
							if(res.data.status == 200){
								window.localStorage.setItem('token',JSON.stringify(res.data))
								this.headhost = 'https://www.biaozhuncha.com/api/media/'+res.data.info.headshot
								if(res.data.info.over_time_day){
									this.viptime = res.data.info.over_time_day.slice(0,10)
								}
								this.vipflag = res.data.info.is_vip
								this.city = res.data.info.city
							}
							if(res.data.status == 404){
								this.tokens = false
							}
						})
					}else{
						this.tokens = false
					}
					
				}
				
			},
		}
	}
</script>

<style scoped>
	.el-header{
		padding: 0;
	}
		.logos{
			width: 0.6rem;
			height: 0.6rem;
			margin-left: 2.4rem;
		}
		  .home{
			  height: 100%;
		  }
		  .homecontainer{
		
			  height: 100vh;
		
		  }
		  .headerone{
			  width: 100%;
			  /* height: 100px; */
			  display: flex;
			  justify-content: space-between;
			  align-items: center;
			  overflow: hidden;
		  }
		  .befores{
			  position: relative;
		  }
		 .befores:before{
			 content: "";/*单引号双引号都可以，但必须是英文*/
			     width: 0.4rem;
			     height: 1px; /*下划线高度*/
			     background: #FFFFFF; /*下划线颜色*/
			     position: absolute;
				 top: 110%;;
		 }
		 .rightlogo{
			 margin-right: 2.4rem;
			 width: 5rem;
			 display: flex;
			 align-items: center;
			 justify-content: space-between;
			 flex-wrap: nowrap;
			 color: #FFFFFF;
			 font-size: 0.16rem;
		 }
		.button1 {
		 background-color: #905321; /* 按钮颜色 */
		   border: none;
		   color: white; /* 文字颜色 */
		   padding: 10px 20px; /* 内部填充 */
		   text-align: center; /* 文字居中 */
		   text-decoration: none;
		   display: inline-block;
		   font-size: 14px; /* 字体大小 */
		   margin: 4px 2px;
		   cursor: pointer;
		   transform: skew(-20deg); /* 倾斜度 */
		   transition: 0.3s;
		   border-radius: 10px 4px 10px 4px;
		}
		 .button1:hover{
			 background-color: #92643e;
		 }
		 .nottitle{
			 background-color: #fceebf;
			 color: #a57c56;
			 border-radius: 10px;
			 padding: 15px 30px;
			 text-align: center;
			 font-weight: 700;
			 margin-top: 10px;
		 }
		 .sy{
			 /* margin-right: 10px; */
		 }
		 .fade-in-image {
		   transition: opacity 0.5s ease-in-out;
  opacity: 0;
		 }
		  
		 .fade-in-image.loaded {
		   opacity: 1;
		 }
</style>
<style>
	.wrap5 {
	            /* 背景色 */ 
	            background:linear-gradient(to right,#f3dcae,#e1bf83) !important;
	            color: #9d7049 !important;
	            border: none !important;
	        }
	.wrap5[x-placement^="bottom"] .popper__arrow::after {
	            border-bottom-color: #e1bf83 !important;
	        }
	
	        .wrap5[x-placement^="bottom"] .popper__arrow {
	            border-bottom-color: #e1bf83 !important;
	        }
</style>